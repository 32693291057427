import { render, staticRenderFns } from "./usefulLinks.vue?vue&type=template&id=74f066cb&scoped=true&"
var script = {}
import style0 from "./usefulLinks.vue?vue&type=style&index=0&id=74f066cb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../Retirement Planner.Admin/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74f066cb",
  null
  
)

export default component.exports